import React, { useEffect } from 'react'

export interface ToastProps {
  id: string
  destroy: () => void
  title: string
  content: string | JSX.Element
  duration?: number
}
interface ToastStyle {
  container: React.CSSProperties
  toast: React.CSSProperties
  toasek: React.CSSProperties
  header: React.CSSProperties
  button: React.CSSProperties
}

const toastStyle: ToastStyle = {
  container: {
    position: 'relative',
    top: '100px', // Anda dapat menyesuaikan posisi vertikal jika diperlukan
    right: '0', // Muncul di sebelah kanan
    zIndex: 9999999,
    display: 'flex',
    flexDirection: 'column',
    width: '250px'
  },
  toast: {
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
    color: 'white',
    width: '100%',
    marginBottom: '10px',
    minHeight: '50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '12px',
    animation: 'slide-in 0.2s',
    transition: 'all 0.3s ease'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  toasek: {
    display: 'flex',
    left: '100rem'
  },
  button: {
    background: 'none',
    border: 'none',
    color: '#ffffff',
    fontWeight: 900,
    outline: 'none',
    cursor: 'pointer',
    borderRadius: '8px',
    padding: '4px 8px'
  }
}
const Toast: React.FC<ToastProps> = (props: any) => {
  const { destroy, content, title, duration = 0 } = props

  useEffect(() => {
    if (!duration) return

    const timer = setTimeout(() => {
      destroy()
    }, duration)

    return () => clearTimeout(timer)
  }, [destroy, duration])

  return (
    <div>
      <div style={toastStyle.container}>
        <div style={toastStyle.header}>
          <div> {title} </div>
          <button style={toastStyle.button} onClick={destroy}>
            X
          </button>
        </div>
        <div style={toastStyle.toast}>{content}</div>
      </div>
    </div>
  )
}

export default Toast
